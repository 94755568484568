<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>和我们，做有挑战的事！</div>
        </div>
      </div>
    </div>
    <div class="container-lg px-4 content py-5">
      <div class="col-12">
        <a
          @click="$router.go(-1)"
          role="button"
          class="btn btn-outline-primary mb-3"
        >
          返回列表
        </a>
      </div>
      <div class="col-12 title fs-1 fw-bold">算法服务平台开发工程师</div>
      <div class="mt-3">
        <div class="badge border text-primary border-primary">开发</div>
        <div class="ms-2 badge border text-primary border-primary">
          社会招聘
        </div>
      </div>
      <div class="description mt-3">
        <div class="fs-4 fw-bold my-4">岗位描述</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              设计并开发基于 Rust 的高性能，低时延算法交易系统；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              设计并开发数据处理平台，监控运维平台；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              设计并开发面向客户的高可用交易工具等；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              设计并开发策略相关的回测平台。
            </li>
          </ul>
        </div>
      </div>

      <div class="requirement mt-3">
        <div class="fs-4 fw-bold my-4">岗位要求</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              本科及以上学历。编程基础扎实，具有良好的计算机理论基础；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              熟练掌握Linux操作，性能分析，具备Rust/C/C++/Java丰富开发经验，熟悉常用的设计模式，有分布式系统开发经验加分；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              有研发高性能，低时延系统经验加分；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              对技术充满热情，思考深入。自我驱动，能快速学习新鲜事物。
            </li>
          </ul>
        </div>
      </div>

      <div class="location mt-3">
        <div class="fs-4 fw-bold my-4">工作城市</div>
        <div class="mt-2">
          <span> 上海 </span>
          <span> 北京 </span>
          <span> 深圳 </span>
          <span> 成都 </span>
          <span> 徐州 </span>
        </div>
      </div>

      <div class="mt-3">
        <div class="fs-4 fw-bold my-4">联系方式</div>
        <div class="mb-2">简历请发送至邮箱：recruit@ft.tech</div>
        <img
          src="../../assets/img/招聘求职.jpg"
          align="center"
        />
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job01",
};
</script>
<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.container-lg {
  border-radius: 3rem;
}
a.btn {
  border-radius: 2rem;
}
img {
  width: 100px;
}
.cover-text > div {
  font-size: 3rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
}
</style>
